import styled from "styled-components";

const Container = styled.div`
  display: inline-block;
  background-color: #eee;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    background-color: #fff;
  }
`;

export function Button({ title, onClick }) {
  return <Container onClick={onClick}>{title}</Container>;
}
