import styled from "styled-components";
import { dark } from "../utils/colors";
import { IoSwapVertical } from "react-icons/io5";
import { useState } from "react";
import { ethers } from "ethers";
import uEthABI from "../abis/uEthABI.json";
import { capitalizeFirstLetter, uETHContractAddress } from "../utils/utils";
import BigNumber from "bignumber.js";

const Container = styled.div`
  position: relative;
  padding: 8px;
  background-color: #888f9d;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 16px;
  border: 6px solid #eee;
`;

const Title = styled.div`
  font-weight: 500;
  padding: 4px 8px;
  font-size: 20px;
  color: ${dark};
  margin-bottom: 8px;
`;

const Side = styled.div`
  position: relative;
  background-color: #eee;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  margin-bottom: 4px;
`;

const Amount = styled.input`
  font-size: 24px;
  color: #333;
  font-family: Roboto Mono;
  text-align: right;
  background-color: transparent;
  border: 0;
  font-weight: 600;
  outline: 0;
`;

const BigButton = styled.div`
  background-color: ${(props) => (props.disabled ? "#6b7078" : dark)};
  color: #fff;
  text-align: center;
  border-radius: 12px;
  font-size: 16px;
  margin-top: 6px;
  padding: 16px 8px;
  cursor: pointer;

  :hover {
    background-color: #ccc;
    color: ${dark};
  }
`;

const SideTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: ${dark};
`;

const SwapContainer = styled.div`
  position: absolute;
  background-color: #4d5b76;
  border-radius: 4px;
  padding: 7px 8px;
  left: 50%;
  line-height: 14px;
  transform: translateX(-50%);
  bottom: -20px;
  cursor: pointer;
  z-index: 2;
`;

const SmallBalance = styled.div`
  font-size: 14px;
  color: #666;
`;

const Left = styled.div`
  flex-grow: 2;
`;

const ErrorBlock = styled.div`
  padding: 8px;
  background-color: #c2453b;
  color: #eee;
  border-radius: 10px;
  margin-top: 8px;
`;

export function SwapBlock({
  account,
  provider,
  connect,
  balance,
  uETHBalance,
}) {
  const [isReverse, setIsReverse] = useState(false);
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const formattedBalance = balance
    ? Number(ethers.utils.formatEther(balance)).toFixed(5)
    : null;
  const formatteduETHBalance = uETHBalance
    ? Number(ethers.utils.formatEther(uETHBalance)).toFixed(5)
    : null;

  let sides = [
    {
      name: "ETH",
      value: value1,
    },
    {
      name: "uETH",
      value: value2,
    },
  ];

  if (isReverse) {
    sides.reverse();
  }

  return (
    <Container>
      <Title>Swap</Title>
      {sides.map((side, index) => (
        <Side key={index}>
          {index === 0 ? (
            <SwapContainer onClick={() => setIsReverse(!isReverse)}>
              <IoSwapVertical size={18} color={"#EEE"} />
            </SwapContainer>
          ) : null}
          <Left>
            <SideTitle>{side.name}</SideTitle>
            {balance ? (
              <SmallBalance>
                Balance:{" "}
                {side.name === "ETH" ? formattedBalance : formatteduETHBalance}{" "}
              </SmallBalance>
            ) : null}
          </Left>

          <Amount
            placeholder={side.name === "ETH" ? "0.0" : "0"}
            type="number"
            pattern="^-?[0-9]\d*\.?\d*$"
            value={side.value}
            onChange={(event) => {
              if (side.name === "ETH") {
                setValue1(event.target.value);

                const bigVal = new BigNumber(event.target.value);
                const result = bigVal.times("1000000");
                setValue2(result.toString());
              } else {
                setValue2(event.target.value);

                const bigVal = new BigNumber(event.target.value);
                const result = bigVal.div("1000000");
                setValue1(result.toString());
              }
            }}
          />
        </Side>
      ))}

      {error ? <ErrorBlock>{capitalizeFirstLetter(error)}</ErrorBlock> : null}

      {!account ? (
        <BigButton onClick={() => connect()}>Connect Wallet</BigButton>
      ) : (
        <BigButton
          disabled={!value1 || Number(value1) <= 0}
          onClick={async () => {
            if (!value1 || isPending) return;

            setIsPending(true);

            try {
              setError(null);
              const signingProvider = provider.getSigner();
              const uEthContract = new ethers.Contract(
                uETHContractAddress,
                uEthABI,
                signingProvider
              );

              if (!isReverse) {
                const tx = await uEthContract.deposit({
                  value: ethers.utils.parseEther(value1),
                });
                await tx.wait();
              } else {
                const tx = await uEthContract.withdraw(
                  ethers.utils.parseEther(value2)
                );
                await tx.wait();
              }

              setValue1("");
              setValue2("");
            } catch (e) {
              console.log("err", JSON.stringify(e));
              if (e.error) {
                setError(e.error.message);
              } else {
                setError(e.message);
              }
            }

            setIsPending(false);
          }}
        >
          {isPending ? "Transaction pending..." : "Swap"}
        </BigButton>
      )}
    </Container>
  );
}
