import "./App.css";
import styled from "styled-components";
import { SwapBlock } from "./components/SwapBlock";
import { Header } from "./components/Header";
import { useWeb3Modal } from "./hooks/useWeb3Modal";
import { useEffect, useState } from "react";
import erc20ABI from "./abis/erc20ABI.json";
import { ethers } from "ethers";
import { uETHContractAddress, watchAsset } from "./utils/utils";

const Container = styled.div``;

const AddToMetamask = styled.a`
  color: #eee;
  font-size: 18px;
  text-align: center;
`;

const AddMetaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 14px;
`

function App() {
  const [provider, account, connect] = useWeb3Modal();
  const [uETHBalance, setUETHBalance] = useState(null);
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    if (!provider || !account) return;

    async function getBalances() {
      const signingProvider = provider.getSigner();
      const ercContract = new ethers.Contract(
        uETHContractAddress,
        erc20ABI,
        signingProvider
      );
      const uETHBalance = await ercContract.balanceOf(account);
      setUETHBalance(uETHBalance);

      const balance = await provider.getBalance(account);
      setBalance(balance);
    }

    provider.on("block", (bal) => {
      getBalances();
    });

    getBalances();

    return () => {
      if (provider) {
        provider.off(account);
      }
    };
  }, [account, provider]);

  return (
    <Container>
      <Header
        handleConnect={connect}
        provider={provider}
        account={account}
        balance={balance}
        uETHBalance={uETHBalance}
      />
      <SwapBlock
        account={account}
        provider={provider}
        connect={connect}
        uETHBalance={uETHBalance}
        balance={balance}
      />

      <AddMetaContainer>
        <AddToMetamask href="#" onClick={() => watchAsset()}>Add uETH to MetaMask</AddToMetamask>
      </AddMetaContainer>
    </Container>
  );
}

export default App;
