import styled from "styled-components";
import { Button } from "./Button";
import { ethers } from "ethers";
import { dark } from "../utils/colors";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 20px 160px;
`;

const Left = styled.div`
  color: #fff;
  font-size: 22px;
  font-family: Roboto Mono;
`;
const Right = styled.div``;

const AccountInfo = styled.div`
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  padding: 6px;
  border-radius: 8px;
  margin-left: 8px;
  font-weight: 600;
`;

const Info = styled.div`
  display: flex;
`;

const Logo = styled.img`
  width: 150px;
`

export function Header({
  handleConnect,
  provider,
  account,
  balance,
  uETHBalance,
}) {
  const balanceDisplay = balance
    ? Number(ethers.utils.formatEther(balance)).toFixed(5)
    : null;

  const uETHBalanceDisplay = uETHBalance
    ? Number(ethers.utils.formatEther(uETHBalance)).toFixed(5)
    : null;

  return (
    <Container>
      <Left><Logo src={"/logo.png"}/></Left>
      <Right>
        {!account ? (
          <Button title={"Connect wallet"} onClick={() => handleConnect()} />
        ) : (
          <Info>
            {" "}
            <AccountInfo color={dark} bgColor={"#EEE"}>
              {balance ? `${Number(balanceDisplay).toFixed(5)} ETH` : "..."}
            </AccountInfo>
            <AccountInfo color={dark} bgColor={"#EEE"}>
              {uETHBalance
                ? `${Number(uETHBalanceDisplay).toFixed(5)} uETH`
                : "..."}
            </AccountInfo>
            <AccountInfo bgColor={"#647391"} color={"#FFF"}>{`${account.substr(
              0,
              4
            )}...${account.substr(-4, 4)}`}</AccountInfo>
          </Info>
        )}
      </Right>
    </Container>
  );
}
