export const uETHContractAddress = '0xbb7be7cc5abb65d3a29d7982236481ddf4f10b09';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function watchAsset() {
  const tokenAddress = uETHContractAddress;
  const tokenSymbol = 'uETH';
  const tokenDecimals = 18;
  const tokenImage = 'https://app.microeth.io/apple-touch-icon.png';

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    // eslint-disable-next-line no-undef
    const wasAdded = await ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log('Thanks for your interest!');
    } else {
      console.log('Your loss!');
    }
  } catch (error) {
    console.log(error);
  }
}
