import Web3Modal from "web3modal";
import {useEffect, useState} from "react";
import { ethers } from "ethers";

const providerOptions = {
  /* See Provider Options Section */
};

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
});

export function useWeb3Modal() {
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState(null);
  const [injectedProvider, setInjectedProvider] = useState(null);

  const connect = async () => {
    const provider = await web3Modal.connect();
    const ethersProvider = new ethers.providers.Web3Provider(provider);
    setProvider(ethersProvider);
    setAccount(window.ethereum.selectedAddress);
  };

  return [provider, account, connect];
}
